<template>
  <section>
    <FormCif
      @submitCifPersonIdentity="editCifPersonIdentity($event)"
      @submitCifPersonProfession="editCifPersonIdentity($event)"
      @submitCifPersonApuppt="editCifPersonIdentity($event)"
      @submitCifEnterPriseIdentity="editCifEnterPriseIdentity($event)"
      @submitCifEnterPriseApuppt="editCifEnterPriseIdentity($event)"
      @getMainId="getMainId($event)"
      :isExisting="true"
    />
  </section>
</template>

<script>
import FormCif from "../form";
import Mixin from "@/views/transactions/m-cif/mixins";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "ExistingCif",
  mixins: [Mixin],
  components: {
    FormCif,
    // eslint-disable-next-line vue/no-unused-components
    PulseLoader,
  },
  data() {
    return {
      submitLoading: false,
      valPojok: process.env.VUE_APP_IS_POJOK,
      validationDomain: ''
    };
  },
  mounted() {
    this.validationDomain = window.location.hostname;
  },
  methods: {
    getMainId(payload) {
      this.mixins_getCifById(payload);
    },
    async editCifPersonIdentity(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "cif/" + payload.cifId,
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open({
                message: "Sukses !",
                type: "is-info",
                duration: 1000,
                position: "is-top",
                queue: false,
              });
              setTimeout(() => {
                this.mixins_getCifById(payload.cifId);
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      } else {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "cif/" + payload.cifId,
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              setTimeout(() => {
                this.mixins_getCifById(payload.cifId);
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      }
    },
    async editCifEnterPriseIdentity(payload) {
      if (this.validationDomain==='pojok.bprshikp.co.id') {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "cif/" + payload.cifId,
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.toast.open({
                message: "Sukses !",
                type: "is-info",
                duration: 1000,
                position: "is-top",
                queue: false,
              });
              setTimeout(() => {
                this.mixins_getCifById(payload.cifId);
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      } else {
        if (!window.navigator.onLine) {
          this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
          return;
        }
        this.submitLoading = true;
        try {
          const resp = await this.$store.dispatch({
            type: "PUT_DATA",
            endPoint: "transaction",
            reqUrl: "cif/" + payload.cifId,
            payload: payload,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.$buefy.snackbar.open(
                this.$NotificationUtils.snackbarSuccess
              );
              setTimeout(() => {
                this.mixins_getCifById(payload.cifId);
              }, 500);
              return;
            }
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } catch (error) {
          setTimeout(() => {
            console.log(error.response);
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: error.response
                ? `${error.response.statusText}`
                : "Terjadi Kesalahan",
              confirmText: "Ok",
              type: "is-danger",
            });
          }, 1500);
        } finally {
          setTimeout(() => {
            this.submitLoading = false;
          }, 1000);
        }
      }
    },
  },
};
</script>
